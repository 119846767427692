import React from 'react';
import PropTypes from 'prop-types';
// import QueueAnim from 'rc-queue-anim';
import { Button } from 'antd';
import Img from 'gatsby-image'

function Banner(props) {
  return (
    <div className="banner-wrapper">
      {props.isMobile && (
        <div className="home-banner-image">
          <img
            alt="banner"
            src="https://gw.alipayobjects.com/zos/rmsportal/rqKQOpnMxeJKngVvulsF.svg"
            width="100%"
          />
        </div>

      )}
      <div className="banner-title-wrapper" type={props.isMobile ? 'bottom' : 'right'}>
        <div key="line" className="title-line-wrapper">
          <div className="title-line" style={{ transform: 'translateX(-64px)' }} />
        </div>
        <h1 key="h1">Super power for your organization</h1>
        <p key="content"> Get everyone charged up, focused &amp; aligned towards company vision &amp; goals  </p>
        <div key="button" className="button-wrapper">
          {/* <a href="http://preview.pro.ant.design" target="_blank" rel="noopener noreferrer">
            <Button type="primary">预览</Button>
          </a> */}
          <Button type="primary"> NOTIFY ME </Button>
        </div>
      </div>
      {!props.isMobile && (
        <div className="banner-image-wrapper">
          <div className="home-banner-image">
            {/* <img alt="banner" src="https://gw.alipayobjects.com/zos/rmsportal/rqKQOpnMxeJKngVvulsF.svg" width="100%" /> */}
            <Img fixed={props.mainImage} alt="banner" width="100%"/>
          </div>
        </div>
      )}
    </div>
  );
}

Banner.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Banner;
