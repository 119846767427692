import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../components/common/Layout'
import Banner from '../components/index_old/Banner'
import Section1 from '../components/index_old/Section1'
import Section2 from '../components/index_old/Section2'
import { graphql, StaticQuery } from 'gatsby';

const IndexPage = ({data}) => (
  <Layout>
    {/* <SEO title="Home" keywords={['gatsby', 'application', 'react']} /> */}
    <Banner isMobile={false} mainImage={data.file.childImageSharp.fixed}/>
    <Section1 isMobile={false} />
    <Section2 />
  </Layout>
)

const IndexPageQuery = props => (
  <StaticQuery
    query={graphql`
            query IndexPage {
                file(relativePath: {eq: "dashboard.png"}) {
                    childImageSharp {
                        fixed(width:550) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
    render={data => <IndexPage data={data} {...props} />}
  />
)

export default IndexPageQuery
